/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../_metronic/helpers'

var _self: HomePage;

class HomePage extends Component<any, any> {

    constructor(props){
        super(props);

        this.state = props;
    }

    render(){
        return(
        <div className='d-flex flex-column flex-root bg-body'>
            <div className='d-flex flex-column flex-column-fluid position-relative bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                    background: `linear-gradient(180deg, rgba(225,254,250,1) 0%, rgba(255,255,255,1) 100%)`,
              }}
            >        
                <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
                    <div className='d-flex flex-center flex-column flex-column-fluid1 p-10 pb-lg-20'>
                        <a href='#' className=''>
                            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-150px' />
                        </a>                                             
                    </div>
                    <div className='row flex-center'>
                        <h2 className='p-10 text-gray-600'>Select a login account</h2>
                    </div>
                    <div className='row flex-center'>
                        <div className='mt-2 mw-300px'>
                            <Link to="/auth" className="btn btn-prdarkgreen w-100 text-white p-4 rounded-lg">LOGIN AS LANDLORD</Link>
                            {/*  <Link to="/auth" className="btn btn-prgreen w-100 text-white p-4 mt-10 rounded-lg">LOGIN AS SELLER</Link> */}
                        </div>
                    </div>
                    <div className='d-flex flex-center flex-column flex-column-fluid1 p-10 pb-lg-20'>
                        <img alt='Logo' src={toAbsoluteUrl('/media/logos/propertyawards.svg')} className='h-75px' />
                        <h4 className='text-gray-600 p-10'>Best Sales & Lettings agent winner<br></br> At the UK Property Awards 2022-2023</h4>
                    </div>
                </div>
            </div> 
        </div>     
        )
    }
}

export default HomePage;
import React, {Component} from "react";
import {Link, withRouter} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'


var _self: PageHeader

class PageHeader extends Component<any, any> {
    constructor(props) {
        super(props)
        this.state = props;
        this.state = {
          title: this.props.title,
          className: []
        }
    
        _self = this
    
      }
      render() {
        const className = this.props.className
        const title = this.props.title
        return (
            <>
            <div className={`card ${className} d-flex flex-wrap mt-10 page-header-card`}>
                {/* begin::Stats */}
                <div className='card mt-n20 bg-prturq position-relative text-center text-white h-100px pt-5 p-5 rounded-0 page-header-box'>
                {/* begin::Row */}
                <div className="d-flex h-100px text-center align-items-center">
                <div className='fs-1x lh-1 text-center fw-bolder w-100'>
                   {title}
                    </div>
                </div>
                
                {/* end::Row */}
                </div>
                {/* end::Stats */}
            </div>
            </>
        )
      }
}

export default withRouter(PageHeader)
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {KTSVG} from '../../../helpers'
import {ChatInner} from '../../chat/ChatInner'
import axios from 'axios'
import {connect} from 'react-redux'
import PageImageHeader from '../../../../app/layout/PageImageHeader'
import PageHeader from '../../../../app/layout/PageHeader'
var _self: any = {}

const API_URL = process.env.REACT_APP_SERVER_HOST
const GET_CHAT_URL = `${API_URL}chat_user_list`

class DrawerMessenger extends Component<any, any> {
  constructor(props: any) {
    super(props)
    console.log('DrawerMessenger')
    let userFullLoggedData = JSON.parse(localStorage.getItem('user_data') as string)
    var opposite_user_role = ''

    opposite_user_role =
      userFullLoggedData.success.role == 'landlord' ? 'agency_manager' : 'landlord'

    const senderId = localStorage.getItem('senderId') as string
    const reapit_id = userFullLoggedData.success.reapit_id

    this.state = {
      //unique_id: new Date().getTime(),
      messageData: {},
      userList: [],
      chatTitle: 'Chat with an agent',
      userLoggedData: userFullLoggedData ? userFullLoggedData.success : '',
      chat_from_user_id: userFullLoggedData ? userFullLoggedData.success.reapit_id : '',
      current_user_role: userFullLoggedData ? userFullLoggedData.success.role : '',
      current_user_name: userFullLoggedData ? userFullLoggedData.success.name : '',
      senderId: senderId,
      chat_to_user_id: senderId,
      reapit_id: reapit_id,
      opposite_user_role: opposite_user_role,
    }
    _self = this
  }

  componentDidMount() {
    this.connectWebSocket()

    this.fetchUserLists()
  }

  connectWebSocket = () => {
    _self.prosConn = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}`) //
    //_self.prosConn.onmessage = _self.onMessage.bind(_self)
    _self.prosConn.onopen = function (event) {
      console.log('Connection established!')
    }

    _self.prosConn.onclose = (event) => {
      // Reconnect after a short delay
      setTimeout(() => {
        this.connectWebSocket()
      }, 1000) // Adjust the delay as needed
    }
  }

  fetchUserLists = () => {
    const requestBody = {
      role: this.state.opposite_user_role,
      reapit_id: this.state.reapit_id,
    }

    axios.get(GET_CHAT_URL, {params: requestBody}).then(function (response) {
      if (response.data.to_user) {
        _self.handleChangeUser(response.data.to_user.to)
        localStorage.setItem('senderId', response.data.to_user.to)
      } else {
        _self.handleChangeUser(response.data.userLists[0].reapit_id)
        localStorage.setItem('senderId', response.data.userLists[0].reapit_id)
      }
      _self.setState({
        userList: response.data.userLists,
      })
    })
  }

  handleChangeUser = (chat_to_user_id) => {
    localStorage.setItem('senderId', chat_to_user_id)
    _self.setState({
      chat_to_user_id: chat_to_user_id,
      unique_id: new Date().getTime(),
    })
  }

  render() {
    const userLoggedData = this.state.userLoggedData
    const userList = this.state.userList
    const chatTitle = this.state.chatTitle

    return (
      <>
        <div
          id='kt_drawer_chat'
          className='bg-white'
          data-kt-drawer='true'
          data-kt-drawer-name='chat'
          data-kt-drawer-activate='true'
          data-kt-drawer-overlay='true'
          data-kt-drawer-direction='end'
          data-kt-drawer-toggle='#kt_drawer_chat_toggle'
          data-kt-drawer-close='#kt_drawer_chat_close'
        >
          <div className='card w-100 rounded-0 mt-10' id='kt_drawer_chat_messenger'>
            {/* <div className='chat-messanger-blank'></div> */}
            <div className='w-100 d-flex p-4'>
              {/* <div
                className='btn btn-sm btn-icon btn-active-light-primary float-left'
                id='kt_drawer_chat_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
              </div> */}
              <button
                className='btn text-dark bg-prgrey p-2 py-1 fs-6 back-button-p'
                id='kt_drawer_chat_close'
              >
                Back
              </button>
            </div>
            <div className='mt-20 page-main-container'>
              <PageHeader title={chatTitle} />
              
              <div className='card-header px-0' id='kt_drawer_chat_messenger_header'>
                <div className='card-title col-md'>
                  <div className='d-flex col-md justify-content-center flex-column me-3 p-5'>
                    <select
                      id='chat_user_list'
                      className='form-select border-0 rounded-lg bg-prgrey fs-7'
                      onChange={(e) => _self.handleChangeUser(e.target.value)}
                    >
                      {Object.keys(userList).length !== 0
                        ? userList.map((userListData, userListKey) => {
                            return (
                              <option
                                value={userListData.reapit_id}
                                selected={
                                  this.state.chat_to_user_id == userListData.reapit_id && true
                                }
                              >
                                {userListData.name}
                              </option>
                            )
                          })
                        : null}
                    </select>
                  </div>

                  {/* <div className='card-toolbar justify-content-end'></div> */}
                </div>
              </div>
              <ChatInner
                isDrawer={true}
                onSend={_self.onSend}
                key={_self.state.unique_id}
                key_id={_self.state.unique_id}
                messageData={_self.state.messageData}
                all_info={_self.state}
                socketCon={_self.prosConn}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default DrawerMessenger

// const mapStateToProps = ({ user }) => {
//   const { userData } =  user;
//   return { userData }
// };

// export default connect(mapStateToProps)(DrawerMessenger);

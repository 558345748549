/* eslint-disable jsx-a11y/anchor-is-valid */
import { auto } from '@popperjs/core';
import React, {Component} from 'react'
import {Link} from 'react-router-dom'

var _self: PropertyItem;

class PropertyItem extends Component<any, any> {

    constructor(props){
        super(props);

        let userFullLoggedData = JSON.parse(localStorage.getItem('persist:v100-demo1-auth') as string );
		let userLoggedData = JSON.parse(userFullLoggedData.user);

        //this.state = props;

        this.state = {
			...props,
			userLoggedData: userLoggedData.success,
        };
    }

    setNegotiatorId = (e,negotiatorId) => {
        e.preventDefault()
        localStorage.setItem('senderId', negotiatorId);
    }

    render(){
        const propertyData = this.state;
        const userLoggedData = this.state.userLoggedData;
        return(
            <div className='card border h-100'>
                <Link
                to={`/profile/property/detail/${propertyData.id}`}
                className='card'
                >
                 <img className="image-container w-100 property-image" src={`${propertyData.icon}`} alt="" />
                 <div className='card-body d-flex flex-column p-2'>
                 <div className='card-title m-0 p-2'>
                <div className='fs-3 fw-bolder text-dark'>{propertyData.title}</div>
            </div>

                    <div className='card-toolbar'>
                    <span className={`badge badge-light-${propertyData.badgeColor} fw-bolder me-auto py-3`}>
                        {propertyData.status}
                    </span>
                    </div>

                    <div className='w-100 pb-5'>
                        <div className='float-end'>
                            <div className='text-gray-800 fs-5 text-end px-3'>
                            {propertyData.id}
                            </div>
                            <div className=''>
                            <Link
                            to={`/profile/property/detail/${propertyData.id}`}
                            className='btn btn-prgreen rounded-lg text-white p-2 fs-8'
                            >View Property Profile</Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className='border border-solid border-prgrey'></div> */}
                    </div>

                    {/*
                    { userLoggedData.role == 'landlord' ?
                        <button onClick={(e) => this.setNegotiatorId(e, propertyData.negotiatorId) }  className='btn btn-primary align-self-center'  id='kt_drawer_chat_toggle'>
                            Message Agency
                        </button>
                    :
                        <button onClick={(e) => this.setNegotiatorId(e, propertyData.landlordId) } className='btn btn-primary align-self-center' id='kt_drawer_chat_toggle'>
                            Message Landlord
                        </button>
                    }  */}
            </Link>
            </div>
        )
    }
}

export default PropertyItem;
